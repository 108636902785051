<script>
  import { ArrowRight } from "lucide-svelte";
  import { cubicOut } from "svelte/easing";
  import { slide } from "svelte/transition";

  import { analytics } from "$lib/analytics";
  import { isMobileMenuOpen } from "$lib/mobile-menu";
</script>

{#if !$isMobileMenuOpen}
  <div
    class="py-2 px-4 flex flex-col sm:flex-row justify-center bg-brand-green text-black text-sm font-medium"
    transition:slide={{ duration: 300, easing: cubicOut }}
  >
    <div class="flex flex-col sm:flex-row sm:gap-x-4">
      <span>Try DeepSeek-R1 on Modal!</span>
      <span class="flex items-center gap-x-1">
        <a
          href="/docs/examples/llama_cpp"
          on:click={() =>
            analytics.track("Announcement Banner DeepSeek-R1 Link Clicked")}
          class="underline">View example</a
        >
        <ArrowRight size="16" />
      </span>
    </div>
  </div>
{/if}
